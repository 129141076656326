#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 65px;

  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s linear;
  z-index: 2000;
  background-color: black;
}
.header-in {
  width: 96vw;
  margin: 0 auto;
  padding: 1rem;
}
.logo {
  float: left;

}

.menuweb {
  float: right;
}
.menuweb .gnb li {
  font-family: "Noto Sans KR", sans-serif;
  display: inline-block;
  font-weight: 500;
  font-size: 0.9rem;
  padding-right: 3vw;
}
.menuweb .gnb li a:link,
a:visited,
a:active,
a:active {
  color: rgb(255, 255, 255);
}
.menuweb .gnb li a:hover {
  color: #47bd9c;
  transition: all 0.2s linear;
}

#header .header-in .logo span {
  /*font-size: px;*/
  color: #fff;
  opacity: 0.5;
  margin-left: 20px;
  font-weight: bold;
}
.logout {
  cursor: pointer;
  font-weight: bold;
  opacity: 1 !important;
  color: white;
  line-height: 25px;
}
.logout:hover {
  transform: scale(1.1);
}
.admin-logo {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 32px;
  height: 18px;
  right: 26px;
  top: 22px;
  display:none;

}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffffff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top:0px;
}

/* General sidebar styles */
.bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15rem;
  background: rgba(0, 0, 0, 1);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 1.8rem;
}

/* Individual item */
.bm-item {
  font-family: "Noto Sans KR", sans-serif;
  display: inline-block;
  border: 0px;
  font-size: 22px;
  font-weight: 500;
  line-height: 88px;
  color: #fff;
  border-bottom: 1px solid rgb(92, 92, 92);
}
.bm-item:focus {
  outline: 0;
}
/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top:0px;
}

.bebe-btn {
  position:absolute;
  right:0;
  top:150px;
}
.bebe-btn img {
  width:130px;
}


@media all and (max-width: 480px) {
  .menuweb {
    display: none;
  }
  .bm-burger-button {
    position: fixed;
    width: 32px;
    height: 18px;
    right: 26px;
    top: 22px;
    display:block;
  }
  .bebe-btn {
    top:100px;
  }
  .bebe-btn img {
    width:90px;
  }

}

#myBtn {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 20px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  width: 60px;
}

.myBtntop {
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: rgb(53, 53, 53); /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 50px; /* Rounded corners */
  font-size: .8rem; /* Increase font size */
  height: 50px;
  opacity: .8;
  margin-bottom: 10px;
}

.myBtntop:hover {
  background-color: #555; /* Add a dark-grey background on hover */
}
