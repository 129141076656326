
@import url(
    'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {

    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    word-break: keep-all;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Noto Sans KR';
    font-family:  Arial, sans-serif;

}
body { overflow: hidden;
    overflow-y: auto;

}
* {
    box-sizing: border-box;
}
a {
    text-decoration: none;
}
li {
    list-style: none;
}
