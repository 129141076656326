#footerview {
  margin-bottom: -300px;
}

.demo-slider-wrapper {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: relative;
  top: 0;
  width: 100%;
}

.demo-slider-wrapper .swiper-container {
  height: 100%;
  width: 100%;
  position: relative;
  touch-action: pan-x pan-y;
}

.slider-image-slide {
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
}

.slider-image-slide-inner {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center;
  width: 100%;
  z-index: 10;
}

.slider-image-slide-img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
  border: 0px;
  object-fit: cover;
}

.img1 {
  background: url("../../resources/maintop_bg04.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img2 {
  background: url("../../resources/maintop_bg05.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img3 {
  background: url("../../resources/maintop_bg06.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* .btn-container-p1 {
  display: flex;
  position: absolute;
  width: 100%;

  top: 38rem;
  z-index: 1000;
  font-size: 1.5rem;
} */

.btn-container-p1 {
  display: flex;
  position: absolute;
  width: 100px;
margin-left:10px;
  top: 68%;
  z-index: 1000;
  font-size: 1.5rem;
}

.btn-wrapper2 {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  margin: auto;
}

.slider-btn-prev,
.slider-btn-next {
  z-index: 1000;
  font-size: 1.5rem;
  color: white;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid white;
  padding: 10px 18px;
  cursor: pointer;
  font-weight: bold;
}

.slider-btn-next {
  margin-left: 10%;
}

.HSworp {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-width: 100%;
  align-items: center;
  overflow: hidden;
}
.Hslide_in {
  width: 80%;
  max-width: 1224px;
}
.HS01 {
  border: 0px;
}
.HS01 .Hslide_in {
  margin-top: 10vh;
  line-height: 1.9rem;
  text-align: left;
  margin: 40vh auto 0 auto;
  line-height: 2.2rem;
  text-align: left;
}
.HS01 .Hslide_in p {
  font-size: 1.6rem;
  color: #fff;
  font-weight: 400;
  line-height: 3.2rem;
  
}
.HS01 .Hslide_in h1 {
  font-size: 2.4rem;
  color: #fff;
  font-weight: 700;
}
.Hgreenfont {
  color: #47bd9c;
  font-weight: 600;
}
.busan_logo {
  display: block;
  margin-top:8px;
}

.HS02 .Hslide_in ul li:nth-child(1) {
  display: inline-block;
  width: 32%;
  margin-right: 8%;  
  height: 52vh;

}
.HS02 .Hslide_in ul li:nth-child(2) {
  display: inline-block;
  width: 60%;
  height: 52vh;
  max-height: 370px;
  background: url("../../resources/protect_img01.png") no-repeat center center /
    cover;
}

.HS03 .Hslide_in ul li:nth-child(1) {  
  float: right;
  width: 32%;
  height: 52vh;

}
.HS03 .Hslide_in ul li:nth-child(2) {
  float: left;
  margin-right: 8%;  
  width: 60%;
  height: 52vh;
  max-height: 370px;
  background: url("../../resources/safety_img01.png") no-repeat center center /
    cover;
}

.HS04 .Hslide_in ul li:nth-child(1) {
  display: inline-block;
  width: 32%;
  margin-right: 8%;  
  height: 52vh;
}
.HS04 .Hslide_in ul li:nth-child(2) {
  display: inline-block;
  width: 60%;
  height: 52vh;
  max-height: 370px;
  background: url("../../resources/continue_img01.png") no-repeat center center /
    cover;
}
.HS06 {
  height: auto;
  max-height:60vh;
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 100%;
  align-items: center;
  overflow: hidden;
}
.HS06 .Hslide_in {
  width: 80%;
  max-width: 80%;
}
.HS06 .Hslide_in p  { 
  margin-bottom: 8vh;
  text-align: center;
}
.HS06 .Hslide_in p span {
  font-size: 1.2rem;
  font-weight: 400;
}
.HS06 .Halliance {
  margin-bottom: 50px;
  text-align: center;  
}

.HS06 .Hslide_in > div div img {
  width: 100%;
  max-width: 150px;
  padding: 15px;
}

.HS06 .Hslide_in > div div h1 {
  font-size: 1.4rem;
  font-weight: 700;
  margin-top: 25px;
}
.HS06 .Hslide_in > div div h2 {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 5px;
}

/* section02 03 04 font bg*/
.Htitle {
  font-size: 4rem;
  color: #333;
  font-weight: 800;
  line-height: 6rem;
}
.Hsubtitle {
  font-size: 1.5rem;
  color: #333;
  font-weight: 700;
  line-height: 4rem;
  padding-left: 4px;
}
.Hcopy {
  font-size: 1rem;
  color: #333;
  font-weight: 400;
  padding-left: 4px;
  padding-top: 15px;
}

.king {
  position: absolute;
  left: 5%;
  z-index: -1;
  max-height: 90%;
}
.king01 {
  position: absolute;
  right: 0%;
  z-index: -1;
  max-height: 90%;
}
/* section02 03 04 font */

/* scroll left text */
.Hscroll {
  position: absolute;
  bottom: 2vh;
  font-size: 0.9375vw;
  z-index: 10;
  font-size: 0.6rem;
  line-height: 1rem;
  text-align: center;
  color: #000;
  animation: scroll 1s ease-in Infinite Alternate;
}
@keyframes scroll {
  0% {
    bottom: 2vh;
  }
  100% {
    bottom: 3vh;
  }
}
.Hlefttext {
  left: 2vw;
  position: absolute;
}
.Hlefttextin {
  font-size: 0.9375vw;
  z-index: 11;
  font-size: 1rem;
  font-weight: 700;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  color: #000000;
}
/* scroll */

/* background img*/
.maintop_bg01 {
  background: url("../../resources/maintop_bg01.png") no-repeat center center /
    cover;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
  border: 0px;
  object-fit: cover;
}
.maintop_bg02 {
  background: url("../../resources/maintop_bg02.png") no-repeat center center /
    cover;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
  border: 0px;
  object-fit: cover;
}
.maintop_bg03 {
  background: url("../../resources/maintop_bg03.png") no-repeat center center /
    cover;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
  border: 0px;
}

.main_bg05 {
  background: url("../../resources/section05_bg.png") no-repeat center / cover;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
}
/* background img*/

.HS07 {
  height: 100px;
  max-height: 200px;
  background:rgb(63, 63, 63);
}

.HS08 {
  height: auto;
  min-height: 900px;
  background-color: #f6fbff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; 
  margin:0px;
  padding:0px;

}
.HS08 .Hslide_in {
  width:80%;
  max-width: 80%;
}
.HS08 .Hslide_in p {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 4.2rem;  
}
.HS08 .Hslide_in h1 {
  font-size: 1rem;
  font-weight: 400;
  margin: 10px 0px 40px 0px;
}
.HS08 .Hslide_in div {
  display: inline-block;
  width: 32.3%;  
  margin:0px 0% 20px 0px;
}

.HS08 .Hslide_in div span{
  font-size: 1.4rem;
  font-weight: 400; 
}
.HS08 .Hslide_in div img {
  width: 100%;  
}

.HS09 {
  height: auto;
  min-height: 980px;
  background-color: #f7f7f7;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:0px;
  padding:0px;

}
.HS09 .Hslide_in {

  max-width: 100%;
}
.HS09 .Hslide_in p {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 4.2rem;
  text-align: center;
}
.HS09 .Hslide_in h1 {
  font-size: 1rem;
  font-weight: 400;
  margin: 10px 0px 40px 0px;
  text-align: center;
}
.HS09 .Hslide_in div {
  display: inline-block;
  width: 16%;
  padding: 2vh;
  text-align: center;
}

.HS09 .Hslide_in div span{
  font-size: 0.8rem;
  font-weight: 600;


}
.HS09 .Hslide_in div img {
  width: 80%;

}
@media all and (max-width: 1200px) {
  .HS09 {
    height: auto;
    min-height: 1250px;
  }

  .HS09 .Hslide_in div {
    width: 25%;
  }
}
@media all and (max-width: 900px) {

  .HS01 .Hslide_in p {
    font-size: 1rem;
    color: #fff;
    font-weight: 300;
    line-height: 1.3rem;
    margin-bottom: 15px;
  }
  .HS01 .Hslide_in h1 {
    font-size: 1.8rem;
    color: #fff;
    font-weight: 700;
    line-height: 2.2rem;
    margin-bottom: 15px;
    width: 90%;
  }
  .Hgreenfont {
    color: #47bd9c;
    font-weight: 600;
  }

  .maintop_bg01 {
    background: url("../../resources/maintop_bg01M.png") no-repeat center center /
      cover;
  }
  .maintop_bg02 {
    background: url("../../resources/maintop_bg02M.png") no-repeat center center /
      cover;
  }
  .maintop_bg03 {
    background: url("../../resources/maintop_bg03M.png") no-repeat center center /
      cover;
  }
  .main_bg05 {
    background: url("../../resources/section01_bgM.png") no-repeat center center /
      cover;
  }

  .HS02 .Hslide_in ul li:nth-child(1) {
    display: inline;
    float: center;
    width: 100%;
    margin-right: 0%;
  }
  .HS02 .Hslide_in ul li img {
    margin-bottom: 4%;
  }
  .HS02 .Hslide_in ul li:nth-child(2) {
    display: inline-block;
    width: 100%;
    height: 22vh;
    margin-top: 5vh;
    background: url("../../resources/protect_img01.png") no-repeat center center /
      cover;
  }

  .HS03 .Hslide_in ul li:nth-child(1) {
    display: inline;
    width: 100%;
    margin-right: 0%;
  }
  .HS03 .Hslide_in ul li img {
    margin-bottom: 4%;
  }
  .HS03 .Hslide_in ul li:nth-child(2) {
    display: inline-block;
    width: 100%;
    height: 22vh;
    margin-top: 5vh;
    background: url("../../resources/safety_img01.png") no-repeat center center /
      cover;
  }

  .HS04 .Hslide_in ul li:nth-child(1) {
    display: inline-block;
    width: 100%;
    margin-right: 0%;
  }
  .HS04 .Hslide_in ul li img {
    margin-bottom: 4%;
  }
  .HS04 .Hslide_in ul li:nth-child(2) {
    display: inline-block;
    width: 100%;
    height: 22vh;
    margin-top: 5vh;
    background: url("../../resources/continue_img01.png") no-repeat center
      center / cover;
  }
  /* scroll left text */
  .Hscroll {
    display: block;
  }

  /* scroll */
  .HS06 .Hslide_in {
    width: 80%;
    max-width: 80%;
    text-align: center;
  }
  .HS06 .Hslide_in p span {
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 8vh;
    text-align: left;
  }
  .HS06 .Halliance {
    margin: 10px 10px 10vh 10px;
  }
  .HS06 .Hslide_in > div div img {
    width: 100%;
  }
  .HS06 .Hslide_in > div div h1 {
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 25px;
    text-align: left;
  }
  .HS06 .Hslide_in > div div h2 {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 5px;
    text-align: left;
  }
  .Hlefttext {
    left: 2vw;
    position: absolute;
    display: none;
  }
  .Htitle {
    font-size: 2.6rem;
    color: #333;
    font-weight: 800;
    line-height: 4rem;
  }
  .Hsubtitle {
    font-size: 1.5rem;
    color: #333;
    font-weight: 700;
    line-height: 3rem;
    padding-left: 4px;
  }
  .Hcopy {
    font-size: 0.9rem;
    color: #333;
    font-weight: 400;
    padding-left: 4px;

  }
  .king {
    display: none;
  }
  .king01 {
    display: none;
  }
}
@media all and (max-width: 640px) {
  .HS09 {
    height: auto;
    min-height: 2050px;
  }

  .HS09 .Hslide_in div {
    width: 50%;
  }
}
@media all and (max-width: 480px) {

  .HS01 .Hslide_in {
    margin-top: 10vh;
    line-height: 1.9rem;
    text-align: left;
    margin: 37vh 0 0 5vh;
    line-height: 1.9rem;
    text-align: left;
  }

  .btn-container-p1 {
    display: flex;
    position: absolute;
    width: 80%;
    justify-content: space-around;
    max-width: 120px;
    top: 72%;
    margin-left: 5px;
    /* left: 13%; */
    z-index: 1000;
    font-size: 1.5rem;
  }
  .HS01 .Hslide_in p {
    font-size: 1rem;
    color: #fff;
    font-weight: 300;
    line-height: 1.3rem;
    margin-bottom: 15px;
  }
  .HS01 .Hslide_in h1 {
    font-size: 1.8rem;
    color: #fff;
    font-weight: 700;
    line-height: 2.2rem;
    margin-bottom: 15px;
    width: 90%;
  }
  .Hgreenfont {
    color: #47bd9c;
    font-weight: 600;
  }

  .img1 {
    background: url("../../resources/maintop_bg01M.png") no-repeat center center /
      cover;
  }

  .img2 {
    background: url("../../resources/maintop_bg02M.png") no-repeat center center /
      cover;
  }

  .img3 {
    background: url("../../resources/maintop_bg03M.png") no-repeat center center /
      cover;
  }

  .maintop_bg01 {
    background: url("../../resources/maintop_bg01M.png") no-repeat center center /
      cover;
  }
  .maintop_bg02 {
    background: url("../../resources/maintop_bg02M.png") no-repeat center center /
      cover;
  }
  .maintop_bg03 {
    background: url("../../resources/maintop_bg03M.png") no-repeat center center /
      cover;
  }
  .main_bg05 {
    background: url("../../resources/section01_bgM.png") no-repeat center center /
      cover;
  }

  .HS02 .Hslide_in ul li:nth-child(1) {
    display: inline;
    float: center;
    width: 100%;
    margin-right: 0%;
  }
  .HS02 .Hslide_in ul li img {
    margin-bottom: 4%;
  }
  .HS02 .Hslide_in ul li:nth-child(2) {
    display: inline-block;
    width: 100%;
    height: 22vh;
    margin-top: 5vh;
    background: url("../../resources/protect_img01.png") no-repeat center center /
      cover;
  }

  .HS03 .Hslide_in ul li:nth-child(1) {
    display: inline;
    width: 100%;
    margin-right: 0%;
  }
  .HS03 .Hslide_in ul li img {
    margin-bottom: 4%;
  }
  .HS03 .Hslide_in ul li:nth-child(2) {
    display: inline-block;
    width: 100%;
    height: 22vh;
    margin-top: 5vh;
    background: url("../../resources/safety_img01.png") no-repeat center center /
      cover;
  }

  .HS04 .Hslide_in ul li:nth-child(1) {
    display: inline-block;
    width: 100%;
    margin-right: 0%;
  }
  .HS04 .Hslide_in ul li img {
    margin-bottom: 4%;
  }
  .HS04 .Hslide_in ul li:nth-child(2) {
    display: inline-block;
    width: 100%;
    height: 22vh;
    margin-top: 5vh;
    background: url("../../resources/continue_img01.png") no-repeat center
      center / cover;
  }
  /* scroll left text */
  .Hscroll {
    display: none;
  }

  /* scroll */

  .HS06 .Hslide_in {
    width: 80%;
    max-width: 80%;
  }
  .HS06 .Hslide_in p {
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 8vh;
  }
  .HS06 .Halliance {
    margin: 10px 0px 10vh 0px;
  }
  .HS06 .Hslide_in > div div img {
    width: 100%;
  }
  /* .HS06 .Hslide_in > div div h1 {
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 25px;
  }
  .HS06 .Hslide_in > div div h2 {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 5px;
  } */
  .Hlefttext {
    left: 2vw;
    position: absolute;
    display: none;
  }
  .Htitle {
    font-size: 2.6rem;
    color: #333;
    font-weight: 800;
    line-height: 4rem;
  }
  .Hsubtitle {
    font-size: 1.5rem;
    color: #333;
    font-weight: 700;
    line-height: 3rem;
    padding-left: 4px;
  }
  .Hcopy {
    font-size: 0.9rem;
    color: #333;
    font-weight: 400;
    padding-left: 4px;
  }
  .king {
    display: none;
  }
  .king01 {
    display: none;
  }


  .HS08 {
    height: 2000px;
    min-height: 2000px;
    background-color: #f3f3f3;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  .HS08 .Hslide_in p {
    font-size: 3rem;
  }
  
  .HS08 .Hslide_in div {
    display: inline-block;
    width: 100%;
    margin:20px 0px 20px 0px;
  
  }
 
}

.swiper-pagination .swiper-pagination-bullet {
  opacity: 0.5;
  background-color: rgb(155, 155, 155);
}

.swiper-pagination .swiper-pagination-bullet-active {
  opacity: 0.5;
  background-color: #1b1b1b;
}

.swiper-button-next {
  /* background: url(/godiva/img/common/next.png) no-repeat; */
  background-size: 50% auto;
  background-position: center;
  background-position-x: 100px;
 
}
.swiper-button-prev { 
  /* background: url(/godiva/img/common/prev.png) no-repeat; */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  /* display: none; */
}

.t123 {
  overflow: hidden;
}

/* .slide-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }

  .swiper {
    width: 100%;
    height: 100%;
    background: #000;
  }

  .swiper-slide {
    font-size: 18px;
    color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 60px;
  }

  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
  }

  .swiper-slide .title {
    font-size: 41px;
    font-weight: 300;
  }

  .swiper-slide .subtitle {
    font-size: 21px;
  }

  .swiper-slide .text {
    font-size: 14px;
    max-width: 400px;
    line-height: 1.3;
  } */
