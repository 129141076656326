/* SignIn.tsx */
.admin-sign-in {
    width: 100%;
    height: 100vh;
    background-color: #000000;
}
.admin-sign-in .sign-in-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}
.admin-sign-in .logo-wrapper {
    margin: 0 auto 70px auto;
}
.admin-sign-in .logo {
    width: 280px;
    margin: auto;
}
.admin-sign-in .logo-txt {
    width: 280px;
    color: white;
    font-weight: bold;
    text-align: right;
    margin: auto;
}
.admin-sign-in .id, .admin-sign-in .pwd {
    width: 480px;
    height: 52px;
    padding: 10px 14px;
    margin-bottom: 8px;
    border: none;
    box-sizing: border-box;
}
.admin-sign-in .btn-login {
    background-color: #47BD9C;
    border: none;
    color: white;
    font-weight: bold;
    padding: 20px 0;
    margin-top: 30px;
    cursor: pointer;
    text-align: center;
}
.admin-sign-in .btn-login:hover {
    background-color: #47BD9CCE;
}

/* *********** */
.admin {
    min-width: 700px;
    max-width: 1440px;
    width: 100%;
}
.admin .title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 50px;
}
.admin .admin-table {
    border: 0;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    box-sizing: border-box;
    border-collapse: collapse;
}
.admin .btn-wrapper{
    width: 100%;
}
.admin .admin-table tr{
    border-bottom: 1px solid #EEE;
}
.admin .admin-table thead td {
    text-align: center;
}
.admin .admin-table thead tr {
    border-bottom: 1px solid #000;
}
.admin .admin-table td {
    padding: 20px;
    box-sizing: border-box;
    height: 100%;
    overflow:auto;
    text-align: center;
}
.admin .admin-table td:nth-child(2) {
    text-align: left;
}
.admin .admin-table .icons{
    display: flex;
    position: absolute;
    left: 50%;
    top: 22px;
    transform: translateX(-50%);
}
.admin .admin-table .icon{
    cursor: pointer;
}
.admin .admin-table .icon:hover{
    transform: scale(1.1);
}
.admin .btn {
    background-color: #000;
    color: white;
    padding: 10px 45px;
    border: none;
    float: right;
    margin-bottom: 20px;
    margin-top: -30px;
    font-weight: bold;
    cursor: pointer;
}
.admin .btn:hover{
    background-color: #00000095;
}


/* VideoModal.js */
.admin-modal-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000020;
}
.admin-modal {
    width: 600px;
    background-color: white;
    border: 1px solid #000000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 4px 4px 4px 3px rgb(46 46 46 / 20%);
    z-index: 2;
}
.admin-modal .modal-title {
    background-color: #000000;
    color: white;
    width: 100%;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
}
.admin-modal .close-btn {
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
}
.admin-modal .close-btn:hover {
    transform: scale(1.1);
}
.admin-modal .content {
    padding: 20px 40px;
}
.admin-modal .content .item{
    margin: 20px 0;
}
.admin-modal .content .item .field{
    width: 100px;
    display: inline-block;
}
.admin-modal .content .item .input{
    width: calc(100% - 100px);
    border: 1px solid #000000;
    border-radius: 0;
    height: 40px;
    padding: 0px 10px;
}
.admin-modal .modal-btn-wrapper{
    width: 100%;
    padding: 20px 40px 40px 40px;
}
.admin-modal .modal-btn-add {
    border: 1px solid #000000;
    margin-right: 10px;
    padding: 5px 35px;
}
.admin-modal .modal-btn-cancel {
    background-color: white;
    color: black;
    border: 1px solid #000000;
    box-sizing: border-box;
    padding: 5px 35px;
}
.admin-modal .modal-btn-cancel:hover{
    background-color: #00000010;
}
.input-file-wrapper{
    display: inline-flex;
    width: calc(100% - 100px);
}
.admin-modal #file{
    display: none;
}
.admin-modal .btn.file{
    margin: 0;
    display: flex;
    align-items: center;
    padding: 5px 35px;
}





.admin-modal input:focus,
.admin-sign-in input:focus{
    /*outline: none;*/
    outline: 1px solid #000000;
}
