@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {

    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    word-break: keep-all;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Noto Sans KR';
    font-family:  Arial, sans-serif;

}
body { overflow: hidden;
    overflow-y: auto;

}
* {
    box-sizing: border-box;
}
a {
    text-decoration: none;
}
li {
    list-style: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


 

#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 65px;

  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s linear;
  z-index: 2000;
  background-color: black;
}
.header-in {
  width: 96vw;
  margin: 0 auto;
  padding: 1rem;
}
.logo {
  float: left;

}

.menuweb {
  float: right;
}
.menuweb .gnb li {
  font-family: "Noto Sans KR", sans-serif;
  display: inline-block;
  font-weight: 500;
  font-size: 0.9rem;
  padding-right: 3vw;
}
.menuweb .gnb li a:link,
a:visited,
a:active,
a:active {
  color: rgb(255, 255, 255);
}
.menuweb .gnb li a:hover {
  color: #47bd9c;
  transition: all 0.2s linear;
}

#header .header-in .logo span {
  /*font-size: px;*/
  color: #fff;
  opacity: 0.5;
  margin-left: 20px;
  font-weight: bold;
}
.logout {
  cursor: pointer;
  font-weight: bold;
  opacity: 1 !important;
  color: white;
  line-height: 25px;
}
.logout:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.admin-logo {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 32px;
  height: 18px;
  right: 26px;
  top: 22px;
  display:none;

}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffffff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top:0px;
}

/* General sidebar styles */
.bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15rem;
  background: rgba(0, 0, 0, 1);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 1.8rem;
}

/* Individual item */
.bm-item {
  font-family: "Noto Sans KR", sans-serif;
  display: inline-block;
  border: 0px;
  font-size: 22px;
  font-weight: 500;
  line-height: 88px;
  color: #fff;
  border-bottom: 1px solid rgb(92, 92, 92);
}
.bm-item:focus {
  outline: 0;
}
/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top:0px;
}

.bebe-btn {
  position:absolute;
  right:0;
  top:150px;
}
.bebe-btn img {
  width:130px;
}


@media all and (max-width: 480px) {
  .menuweb {
    display: none;
  }
  .bm-burger-button {
    position: fixed;
    width: 32px;
    height: 18px;
    right: 26px;
    top: 22px;
    display:block;
  }
  .bebe-btn {
    top:100px;
  }
  .bebe-btn img {
    width:90px;
  }

}

#myBtn {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 20px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  width: 60px;
}

.myBtntop {
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: rgb(53, 53, 53); /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 50px; /* Rounded corners */
  font-size: .8rem; /* Increase font size */
  height: 50px;
  opacity: .8;
  margin-bottom: 10px;
}

.myBtntop:hover {
  background-color: #555; /* Add a dark-grey background on hover */
}

/* SignIn.tsx */
.admin-sign-in {
    width: 100%;
    height: 100vh;
    background-color: #000000;
}
.admin-sign-in .sign-in-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}
.admin-sign-in .logo-wrapper {
    margin: 0 auto 70px auto;
}
.admin-sign-in .logo {
    width: 280px;
    margin: auto;
}
.admin-sign-in .logo-txt {
    width: 280px;
    color: white;
    font-weight: bold;
    text-align: right;
    margin: auto;
}
.admin-sign-in .id, .admin-sign-in .pwd {
    width: 480px;
    height: 52px;
    padding: 10px 14px;
    margin-bottom: 8px;
    border: none;
    box-sizing: border-box;
}
.admin-sign-in .btn-login {
    background-color: #47BD9C;
    border: none;
    color: white;
    font-weight: bold;
    padding: 20px 0;
    margin-top: 30px;
    cursor: pointer;
    text-align: center;
}
.admin-sign-in .btn-login:hover {
    background-color: #47BD9CCE;
}

/* *********** */
.admin {
    min-width: 700px;
    max-width: 1440px;
    width: 100%;
}
.admin .title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 50px;
}
.admin .admin-table {
    border: 0;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    box-sizing: border-box;
    border-collapse: collapse;
}
.admin .btn-wrapper{
    width: 100%;
}
.admin .admin-table tr{
    border-bottom: 1px solid #EEE;
}
.admin .admin-table thead td {
    text-align: center;
}
.admin .admin-table thead tr {
    border-bottom: 1px solid #000;
}
.admin .admin-table td {
    padding: 20px;
    box-sizing: border-box;
    height: 100%;
    overflow:auto;
    text-align: center;
}
.admin .admin-table td:nth-child(2) {
    text-align: left;
}
.admin .admin-table .icons{
    display: flex;
    position: absolute;
    left: 50%;
    top: 22px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.admin .admin-table .icon{
    cursor: pointer;
}
.admin .admin-table .icon:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.admin .btn {
    background-color: #000;
    color: white;
    padding: 10px 45px;
    border: none;
    float: right;
    margin-bottom: 20px;
    margin-top: -30px;
    font-weight: bold;
    cursor: pointer;
}
.admin .btn:hover{
    background-color: #00000095;
}


/* VideoModal.js */
.admin-modal-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000020;
}
.admin-modal {
    width: 600px;
    background-color: white;
    border: 1px solid #000000;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    box-shadow: 4px 4px 4px 3px rgb(46 46 46 / 20%);
    z-index: 2;
}
.admin-modal .modal-title {
    background-color: #000000;
    color: white;
    width: 100%;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
}
.admin-modal .close-btn {
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
}
.admin-modal .close-btn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.admin-modal .content {
    padding: 20px 40px;
}
.admin-modal .content .item{
    margin: 20px 0;
}
.admin-modal .content .item .field{
    width: 100px;
    display: inline-block;
}
.admin-modal .content .item .input{
    width: calc(100% - 100px);
    border: 1px solid #000000;
    border-radius: 0;
    height: 40px;
    padding: 0px 10px;
}
.admin-modal .modal-btn-wrapper{
    width: 100%;
    padding: 20px 40px 40px 40px;
}
.admin-modal .modal-btn-add {
    border: 1px solid #000000;
    margin-right: 10px;
    padding: 5px 35px;
}
.admin-modal .modal-btn-cancel {
    background-color: white;
    color: black;
    border: 1px solid #000000;
    box-sizing: border-box;
    padding: 5px 35px;
}
.admin-modal .modal-btn-cancel:hover{
    background-color: #00000010;
}
.input-file-wrapper{
    display: inline-flex;
    width: calc(100% - 100px);
}
.admin-modal #file{
    display: none;
}
.admin-modal .btn.file{
    margin: 0;
    display: flex;
    align-items: center;
    padding: 5px 35px;
}





.admin-modal input:focus,
.admin-sign-in input:focus{
    /*outline: none;*/
    outline: 1px solid #000000;
}

#footerview {
  margin-bottom: -300px;
}

.demo-slider-wrapper {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: relative;
  top: 0;
  width: 100%;
}

.demo-slider-wrapper .swiper-container {
  height: 100%;
  width: 100%;
  position: relative;
  touch-action: pan-x pan-y;
}

.slider-image-slide {
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
}

.slider-image-slide-inner {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform-origin: center;
          transform-origin: center;
  width: 100%;
  z-index: 10;
}

.slider-image-slide-img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
  border: 0px;
  object-fit: cover;
}

.img1 {
  background: url(/static/media/maintop_bg04.570787e2.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img2 {
  background: url(/static/media/maintop_bg05.841da987.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img3 {
  background: url(/static/media/maintop_bg06.529e0574.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* .btn-container-p1 {
  display: flex;
  position: absolute;
  width: 100%;

  top: 38rem;
  z-index: 1000;
  font-size: 1.5rem;
} */

.btn-container-p1 {
  display: flex;
  position: absolute;
  width: 100px;
margin-left:10px;
  top: 68%;
  z-index: 1000;
  font-size: 1.5rem;
}

.btn-wrapper2 {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  margin: auto;
}

.slider-btn-prev,
.slider-btn-next {
  z-index: 1000;
  font-size: 1.5rem;
  color: white;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid white;
  padding: 10px 18px;
  cursor: pointer;
  font-weight: bold;
}

.slider-btn-next {
  margin-left: 10%;
}

.HSworp {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-width: 100%;
  align-items: center;
  overflow: hidden;
}
.Hslide_in {
  width: 80%;
  max-width: 1224px;
}
.HS01 {
  border: 0px;
}
.HS01 .Hslide_in {
  margin-top: 10vh;
  line-height: 1.9rem;
  text-align: left;
  margin: 40vh auto 0 auto;
  line-height: 2.2rem;
  text-align: left;
}
.HS01 .Hslide_in p {
  font-size: 1.6rem;
  color: #fff;
  font-weight: 400;
  line-height: 3.2rem;
  
}
.HS01 .Hslide_in h1 {
  font-size: 2.4rem;
  color: #fff;
  font-weight: 700;
}
.Hgreenfont {
  color: #47bd9c;
  font-weight: 600;
}
.busan_logo {
  display: block;
  margin-top:8px;
}

.HS02 .Hslide_in ul li:nth-child(1) {
  display: inline-block;
  width: 32%;
  margin-right: 8%;  
  height: 52vh;

}
.HS02 .Hslide_in ul li:nth-child(2) {
  display: inline-block;
  width: 60%;
  height: 52vh;
  max-height: 370px;
  background: url(/static/media/protect_img01.0d21da55.png) no-repeat center center /
    cover;
}

.HS03 .Hslide_in ul li:nth-child(1) {  
  float: right;
  width: 32%;
  height: 52vh;

}
.HS03 .Hslide_in ul li:nth-child(2) {
  float: left;
  margin-right: 8%;  
  width: 60%;
  height: 52vh;
  max-height: 370px;
  background: url(/static/media/safety_img01.f74f8f66.png) no-repeat center center /
    cover;
}

.HS04 .Hslide_in ul li:nth-child(1) {
  display: inline-block;
  width: 32%;
  margin-right: 8%;  
  height: 52vh;
}
.HS04 .Hslide_in ul li:nth-child(2) {
  display: inline-block;
  width: 60%;
  height: 52vh;
  max-height: 370px;
  background: url(/static/media/continue_img01.6a55c050.png) no-repeat center center /
    cover;
}
.HS06 {
  height: auto;
  max-height:60vh;
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 100%;
  align-items: center;
  overflow: hidden;
}
.HS06 .Hslide_in {
  width: 80%;
  max-width: 80%;
}
.HS06 .Hslide_in p  { 
  margin-bottom: 8vh;
  text-align: center;
}
.HS06 .Hslide_in p span {
  font-size: 1.2rem;
  font-weight: 400;
}
.HS06 .Halliance {
  margin-bottom: 50px;
  text-align: center;  
}

.HS06 .Hslide_in > div div img {
  width: 100%;
  max-width: 150px;
  padding: 15px;
}

.HS06 .Hslide_in > div div h1 {
  font-size: 1.4rem;
  font-weight: 700;
  margin-top: 25px;
}
.HS06 .Hslide_in > div div h2 {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 5px;
}

/* section02 03 04 font bg*/
.Htitle {
  font-size: 4rem;
  color: #333;
  font-weight: 800;
  line-height: 6rem;
}
.Hsubtitle {
  font-size: 1.5rem;
  color: #333;
  font-weight: 700;
  line-height: 4rem;
  padding-left: 4px;
}
.Hcopy {
  font-size: 1rem;
  color: #333;
  font-weight: 400;
  padding-left: 4px;
  padding-top: 15px;
}

.king {
  position: absolute;
  left: 5%;
  z-index: -1;
  max-height: 90%;
}
.king01 {
  position: absolute;
  right: 0%;
  z-index: -1;
  max-height: 90%;
}
/* section02 03 04 font */

/* scroll left text */
.Hscroll {
  position: absolute;
  bottom: 2vh;
  font-size: 0.9375vw;
  z-index: 10;
  font-size: 0.6rem;
  line-height: 1rem;
  text-align: center;
  color: #000;
  -webkit-animation: scroll 1s ease-in Infinite Alternate;
          animation: scroll 1s ease-in Infinite Alternate;
}
@-webkit-keyframes scroll {
  0% {
    bottom: 2vh;
  }
  100% {
    bottom: 3vh;
  }
}
@keyframes scroll {
  0% {
    bottom: 2vh;
  }
  100% {
    bottom: 3vh;
  }
}
.Hlefttext {
  left: 2vw;
  position: absolute;
}
.Hlefttextin {
  font-size: 0.9375vw;
  z-index: 11;
  font-size: 1rem;
  font-weight: 700;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  color: #000000;
}
/* scroll */

/* background img*/
.maintop_bg01 {
  background: url(/static/media/maintop_bg01.7f5ef6d3.png) no-repeat center center /
    cover;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
  border: 0px;
  object-fit: cover;
}
.maintop_bg02 {
  background: url(/static/media/maintop_bg02.32870f09.png) no-repeat center center /
    cover;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
  border: 0px;
  object-fit: cover;
}
.maintop_bg03 {
  background: url(/static/media/maintop_bg03.4625f8e2.png) no-repeat center center /
    cover;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
  border: 0px;
}

.main_bg05 {
  background: url(/static/media/section05_bg.6ca98c67.png) no-repeat center / cover;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
}
/* background img*/

.HS07 {
  height: 100px;
  max-height: 200px;
  background:rgb(63, 63, 63);
}

.HS08 {
  height: auto;
  min-height: 900px;
  background-color: #f6fbff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; 
  margin:0px;
  padding:0px;

}
.HS08 .Hslide_in {
  width:80%;
  max-width: 80%;
}
.HS08 .Hslide_in p {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 4.2rem;  
}
.HS08 .Hslide_in h1 {
  font-size: 1rem;
  font-weight: 400;
  margin: 10px 0px 40px 0px;
}
.HS08 .Hslide_in div {
  display: inline-block;
  width: 32.3%;  
  margin:0px 0% 20px 0px;
}

.HS08 .Hslide_in div span{
  font-size: 1.4rem;
  font-weight: 400; 
}
.HS08 .Hslide_in div img {
  width: 100%;  
}

.HS09 {
  height: auto;
  min-height: 980px;
  background-color: #f7f7f7;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:0px;
  padding:0px;

}
.HS09 .Hslide_in {

  max-width: 100%;
}
.HS09 .Hslide_in p {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 4.2rem;
  text-align: center;
}
.HS09 .Hslide_in h1 {
  font-size: 1rem;
  font-weight: 400;
  margin: 10px 0px 40px 0px;
  text-align: center;
}
.HS09 .Hslide_in div {
  display: inline-block;
  width: 16%;
  padding: 2vh;
  text-align: center;
}

.HS09 .Hslide_in div span{
  font-size: 0.8rem;
  font-weight: 600;


}
.HS09 .Hslide_in div img {
  width: 80%;

}
@media all and (max-width: 1200px) {
  .HS09 {
    height: auto;
    min-height: 1250px;
  }

  .HS09 .Hslide_in div {
    width: 25%;
  }
}
@media all and (max-width: 900px) {

  .HS01 .Hslide_in p {
    font-size: 1rem;
    color: #fff;
    font-weight: 300;
    line-height: 1.3rem;
    margin-bottom: 15px;
  }
  .HS01 .Hslide_in h1 {
    font-size: 1.8rem;
    color: #fff;
    font-weight: 700;
    line-height: 2.2rem;
    margin-bottom: 15px;
    width: 90%;
  }
  .Hgreenfont {
    color: #47bd9c;
    font-weight: 600;
  }

  .maintop_bg01 {
    background: url(/static/media/maintop_bg01M.736a6eb6.png) no-repeat center center /
      cover;
  }
  .maintop_bg02 {
    background: url(/static/media/maintop_bg02M.efc1c58c.png) no-repeat center center /
      cover;
  }
  .maintop_bg03 {
    background: url(/static/media/maintop_bg03M.9a9d2d28.png) no-repeat center center /
      cover;
  }
  .main_bg05 {
    background: url(/static/media/section01_bgM.3f5eed9a.png) no-repeat center center /
      cover;
  }

  .HS02 .Hslide_in ul li:nth-child(1) {
    display: inline;
    float: center;
    width: 100%;
    margin-right: 0%;
  }
  .HS02 .Hslide_in ul li img {
    margin-bottom: 4%;
  }
  .HS02 .Hslide_in ul li:nth-child(2) {
    display: inline-block;
    width: 100%;
    height: 22vh;
    margin-top: 5vh;
    background: url(/static/media/protect_img01.0d21da55.png) no-repeat center center /
      cover;
  }

  .HS03 .Hslide_in ul li:nth-child(1) {
    display: inline;
    width: 100%;
    margin-right: 0%;
  }
  .HS03 .Hslide_in ul li img {
    margin-bottom: 4%;
  }
  .HS03 .Hslide_in ul li:nth-child(2) {
    display: inline-block;
    width: 100%;
    height: 22vh;
    margin-top: 5vh;
    background: url(/static/media/safety_img01.f74f8f66.png) no-repeat center center /
      cover;
  }

  .HS04 .Hslide_in ul li:nth-child(1) {
    display: inline-block;
    width: 100%;
    margin-right: 0%;
  }
  .HS04 .Hslide_in ul li img {
    margin-bottom: 4%;
  }
  .HS04 .Hslide_in ul li:nth-child(2) {
    display: inline-block;
    width: 100%;
    height: 22vh;
    margin-top: 5vh;
    background: url(/static/media/continue_img01.6a55c050.png) no-repeat center
      center / cover;
  }
  /* scroll left text */
  .Hscroll {
    display: block;
  }

  /* scroll */
  .HS06 .Hslide_in {
    width: 80%;
    max-width: 80%;
    text-align: center;
  }
  .HS06 .Hslide_in p span {
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 8vh;
    text-align: left;
  }
  .HS06 .Halliance {
    margin: 10px 10px 10vh 10px;
  }
  .HS06 .Hslide_in > div div img {
    width: 100%;
  }
  .HS06 .Hslide_in > div div h1 {
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 25px;
    text-align: left;
  }
  .HS06 .Hslide_in > div div h2 {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 5px;
    text-align: left;
  }
  .Hlefttext {
    left: 2vw;
    position: absolute;
    display: none;
  }
  .Htitle {
    font-size: 2.6rem;
    color: #333;
    font-weight: 800;
    line-height: 4rem;
  }
  .Hsubtitle {
    font-size: 1.5rem;
    color: #333;
    font-weight: 700;
    line-height: 3rem;
    padding-left: 4px;
  }
  .Hcopy {
    font-size: 0.9rem;
    color: #333;
    font-weight: 400;
    padding-left: 4px;

  }
  .king {
    display: none;
  }
  .king01 {
    display: none;
  }
}
@media all and (max-width: 640px) {
  .HS09 {
    height: auto;
    min-height: 2050px;
  }

  .HS09 .Hslide_in div {
    width: 50%;
  }
}
@media all and (max-width: 480px) {

  .HS01 .Hslide_in {
    margin-top: 10vh;
    line-height: 1.9rem;
    text-align: left;
    margin: 37vh 0 0 5vh;
    line-height: 1.9rem;
    text-align: left;
  }

  .btn-container-p1 {
    display: flex;
    position: absolute;
    width: 80%;
    justify-content: space-around;
    max-width: 120px;
    top: 72%;
    margin-left: 5px;
    /* left: 13%; */
    z-index: 1000;
    font-size: 1.5rem;
  }
  .HS01 .Hslide_in p {
    font-size: 1rem;
    color: #fff;
    font-weight: 300;
    line-height: 1.3rem;
    margin-bottom: 15px;
  }
  .HS01 .Hslide_in h1 {
    font-size: 1.8rem;
    color: #fff;
    font-weight: 700;
    line-height: 2.2rem;
    margin-bottom: 15px;
    width: 90%;
  }
  .Hgreenfont {
    color: #47bd9c;
    font-weight: 600;
  }

  .img1 {
    background: url(/static/media/maintop_bg01M.736a6eb6.png) no-repeat center center /
      cover;
  }

  .img2 {
    background: url(/static/media/maintop_bg02M.efc1c58c.png) no-repeat center center /
      cover;
  }

  .img3 {
    background: url(/static/media/maintop_bg03M.9a9d2d28.png) no-repeat center center /
      cover;
  }

  .maintop_bg01 {
    background: url(/static/media/maintop_bg01M.736a6eb6.png) no-repeat center center /
      cover;
  }
  .maintop_bg02 {
    background: url(/static/media/maintop_bg02M.efc1c58c.png) no-repeat center center /
      cover;
  }
  .maintop_bg03 {
    background: url(/static/media/maintop_bg03M.9a9d2d28.png) no-repeat center center /
      cover;
  }
  .main_bg05 {
    background: url(/static/media/section01_bgM.3f5eed9a.png) no-repeat center center /
      cover;
  }

  .HS02 .Hslide_in ul li:nth-child(1) {
    display: inline;
    float: center;
    width: 100%;
    margin-right: 0%;
  }
  .HS02 .Hslide_in ul li img {
    margin-bottom: 4%;
  }
  .HS02 .Hslide_in ul li:nth-child(2) {
    display: inline-block;
    width: 100%;
    height: 22vh;
    margin-top: 5vh;
    background: url(/static/media/protect_img01.0d21da55.png) no-repeat center center /
      cover;
  }

  .HS03 .Hslide_in ul li:nth-child(1) {
    display: inline;
    width: 100%;
    margin-right: 0%;
  }
  .HS03 .Hslide_in ul li img {
    margin-bottom: 4%;
  }
  .HS03 .Hslide_in ul li:nth-child(2) {
    display: inline-block;
    width: 100%;
    height: 22vh;
    margin-top: 5vh;
    background: url(/static/media/safety_img01.f74f8f66.png) no-repeat center center /
      cover;
  }

  .HS04 .Hslide_in ul li:nth-child(1) {
    display: inline-block;
    width: 100%;
    margin-right: 0%;
  }
  .HS04 .Hslide_in ul li img {
    margin-bottom: 4%;
  }
  .HS04 .Hslide_in ul li:nth-child(2) {
    display: inline-block;
    width: 100%;
    height: 22vh;
    margin-top: 5vh;
    background: url(/static/media/continue_img01.6a55c050.png) no-repeat center
      center / cover;
  }
  /* scroll left text */
  .Hscroll {
    display: none;
  }

  /* scroll */

  .HS06 .Hslide_in {
    width: 80%;
    max-width: 80%;
  }
  .HS06 .Hslide_in p {
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 8vh;
  }
  .HS06 .Halliance {
    margin: 10px 0px 10vh 0px;
  }
  .HS06 .Hslide_in > div div img {
    width: 100%;
  }
  /* .HS06 .Hslide_in > div div h1 {
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 25px;
  }
  .HS06 .Hslide_in > div div h2 {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 5px;
  } */
  .Hlefttext {
    left: 2vw;
    position: absolute;
    display: none;
  }
  .Htitle {
    font-size: 2.6rem;
    color: #333;
    font-weight: 800;
    line-height: 4rem;
  }
  .Hsubtitle {
    font-size: 1.5rem;
    color: #333;
    font-weight: 700;
    line-height: 3rem;
    padding-left: 4px;
  }
  .Hcopy {
    font-size: 0.9rem;
    color: #333;
    font-weight: 400;
    padding-left: 4px;
  }
  .king {
    display: none;
  }
  .king01 {
    display: none;
  }


  .HS08 {
    height: 2000px;
    min-height: 2000px;
    background-color: #f3f3f3;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  .HS08 .Hslide_in p {
    font-size: 3rem;
  }
  
  .HS08 .Hslide_in div {
    display: inline-block;
    width: 100%;
    margin:20px 0px 20px 0px;
  
  }
 
}

.swiper-pagination .swiper-pagination-bullet {
  opacity: 0.5;
  background-color: rgb(155, 155, 155);
}

.swiper-pagination .swiper-pagination-bullet-active {
  opacity: 0.5;
  background-color: #1b1b1b;
}

.swiper-button-next {
  /* background: url(/godiva/img/common/next.png) no-repeat; */
  background-size: 50% auto;
  background-position: center;
  background-position-x: 100px;
 
}
.swiper-button-prev { 
  /* background: url(/godiva/img/common/prev.png) no-repeat; */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  /* display: none; */
}

.t123 {
  overflow: hidden;
}

/* .slide-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }

  .swiper {
    width: 100%;
    height: 100%;
    background: #000;
  }

  .swiper-slide {
    font-size: 18px;
    color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 60px;
  }

  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
  }

  .swiper-slide .title {
    font-size: 41px;
    font-weight: 300;
  }

  .swiper-slide .subtitle {
    font-size: 21px;
  }

  .swiper-slide .text {
    font-size: 14px;
    max-width: 400px;
    line-height: 1.3;
  } */

.typewriter {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0rem solid rgb(0, 0, 0); /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0rem; /* Adjust as needed */
    -webkit-animation: 
      typing 4.5s steps(40, end),
      blink-caret .75s step-end infinite;
            animation: 
      typing 4.5s steps(40, end),
      blink-caret .75s step-end infinite;
  }
  
  /* The typing effect */
  @-webkit-keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @-webkit-keyframes blink-caret {
    from, to { border-color: transparent }
    70% { border-color: rgb(0, 0, 0); }
  }
  @keyframes blink-caret {
    from, to { border-color: transparent }
    70% { border-color: rgb(0, 0, 0); }
  }


 /* CSS Animated Background Gradient */

  .hbgdo {
    position:absolute;
    z-index: -1;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    background-color:black;
    -webkit-filter: grayscale(20%) brightness(70%); /* Safari 6.0 - 9.0 */
    filter: grayscale(20%) brightness(70%);
    
}

.type-a {  
    width: 100vw;
    height: 100vh; 
    -webkit-animation: zoom-a 10s linear infinite alternate; /* Safari 4+ */ /* Fx 5+ */ /* Opera 12+ */
    animation:         zoom-a 10s linear infinite alternate; /* IE 10+, Fx 29+ */
  }
@-webkit-keyframes zoom-a {
	0% { background-size: 120% auto; }
	100% { background-size: 140% auto; }
}
@keyframes zoom-a {
	0% { background-size: 120% auto; }
	100% { background-size: 140% auto; }
}

.type-b {  
    width: 110%;
    height: 110%;
    -webkit-animation: zoom-a 6s infinite alternate; /* Safari 6+ */ /* Fx 5+ */ /* Opera 12+ */
    animation:         zoom-a 6s infinite alternate; /* IE 10+, Fx 29+ */
  }
  @-webkit-keyframes zoom-a {
	0% { background-size: 140% auto; }
    50% { background-size: 120% auto; }
	100% { background-size: 105% auto; }
}
@keyframes zoom-a {
	0% { background-size: 140% auto; }
    50% { background-size: 120% auto; }
	100% { background-size: 105% auto; }
}

.chatbox {animation: motion 0.3s linear 0s infinite alternate; margin-top: 0;
	-webkit-animation: motion 0.3s linear 0s infinite alternate; margin-top: 0;
}
        
@-webkit-keyframes motion {
	0% {margin-top: 0px;}
	100% {margin-top: 10px;}
}
        
@keyframes motion {
	0% {margin-top: 0px;}
	100% {margin-top: 10px;}
}

-webkit-@keyframes motion {
	0% {margin-top: 0px;}
	100% {margin-top: 10px;}
}

.z-scroll {
    opacity: 0;
    -webkit-transform: translateX(-80px);
            transform: translateX(-80px);
    transition: opacity 1.0s, -webkit-transform 1.0s;
    transition: transform 1.0s, opacity 1.0s;
    transition: transform 1.0s, opacity 1.0s, -webkit-transform 1.0s;
  }
  .z-scroll.right-on {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    transition: opacity 1.0s, -webkit-transform 1.0s;
    transition: transform 1.0s, opacity 1.0s;
    transition: transform 1.0s, opacity 1.0s, -webkit-transform 1.0s;
  }

#talk{
    position: relative;
    
}
.talk-txt {top:-2vh;}
.talk-txt .title {
  display:block;
  width: 66vw;
  margin:10vh auto 0;
}

.talk-txt span {
    width:50vw;
    display:block;
    margin:auto;
    position:relative;
}

.talk-txt div.tlk_01 {
    margin-top:15vh;
    margin-left:10vh;
}
.talk-txt div.tlk_02 {
    margin-top:5vh;
    margin-left:30vh;
}
.talk-txt div.tlk_03 {
    margin-top:5vh;

}
.talk-txt div.tlk_01 img {
    width:19vw;
}
.talk-txt div.tlk_02 img {
    width:16vw;
}
.talk-txt div.tlk_03 img {
    width:25vw;
}

/* animation */
.talk-txt div.tlk_01.z-scroll2.up-on {
    transition-delay: 0.4s;
}
.talk-txt div.tlk_02.z-scroll2.up-on {
    transition-delay: 1.6s;
}
.talk-txt div.tlk_03.z-scroll2.up-on {
    transition-delay: 2.8s;
}
#talk .title {
  transition-delay: 3.6s;
}



/* CSS Animated Background Gradient */



.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 9;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    -webkit-animation: animate 25s linear infinite;
            animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 12s;
            animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 18s;
            animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    -webkit-animation-delay: 15s;
            animation-delay: 15s;
    -webkit-animation-duration: 45s;
            animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 35s;
            animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 11s;
            animation-duration: 11s;
}



@-webkit-keyframes animate {

    0%{
        -webkit-transform: translateY(0) rotate(0deg);
                transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        -webkit-transform: translateY(-1000px) rotate(720deg);
                transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}



@keyframes animate {

    0%{
        -webkit-transform: translateY(0) rotate(0deg);
                transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        -webkit-transform: translateY(-1000px) rotate(720deg);
                transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}


  /* CSS Animated Background Gradient */
  .HTbg {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 300% 300%;
    background-image: linear-gradient(
          -45deg, 
            rgba(59,173,227,1) 0%, 
          rgba(87,111,230,1) 25%, 
          rgba(71,189,126,1) 51%, 
          rgba(255,245,0,1) 100%
          /* rgba(59,173,227,1) 0%, 
          rgba(87,111,230,1) 25%, 
          rgba(152,68,183,1) 51%, 
          rgba(255,53,127,1) 100% */
    );  
    -webkit-animation: AnimateBG 20s ease infinite;  
            animation: AnimateBG 20s ease infinite;
  }
  
  @-webkit-keyframes AnimateBG { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  
  @keyframes AnimateBG { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }


  /* Seeding */
  





  

#footer {
    display: flex;
    justify-Content: space-around;
    background:rgb(63, 63, 63);
 
}
.footer-in{
    display: block;
    width: 90vw;
    height: auto;
    max-width: 1224px;
    margin-top: 5vh;
    margin-bottom: 5vh;
    color: #fff;
    font-size: .8rem;
}

.companySworp{
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
 
    overflow-x: hidden;     
    /* background:url("../../resources/vidotop_img01.png") no-repeat top center / 100%;  
    background-position: -70px 0px 0px 0px; */
}
.topimg{
    position: absolute;
    width: 100%;
    top: -100px;  
}
.topimg img{
    width: 100%;    
}
.company_in {
    margin-top: 25%;
    width: 80%;
    max-width: 1224px;    
    margin-bottom: 10%;    
}
.company .company_in ul li:nth-child(1) {  
    float: left;
    width: 50%; 
    margin-right: 2%;
    margin-top: 2%;
}
.company .company_in ul li:nth-child(2) {     
    float: right;
    width: 40%;  
    height: 560px;  
    background:url(/static/media/company_img01.bb0e9423.png) no-repeat center center / cover;  
    font-size: 1rem;
    color: #333;
    font-weight: 400; 
    padding-left: 4px;
    margin-top: 50px;
}
.company .company_in ul li p {
    font-size: 3rem;
    color: #333;
    font-weight: 800;  
    line-height: 6rem;
}    
.company .company_in ul li h1 {
    font-size: 1.2rem;
    color: #333;
    font-weight: 900; 
    padding-left: 4px;
}

.Hgreenfont{
    color: #47BD9C;
    font-weight: 600;  
}










@media all and (max-width:480px){   
    .company .company_in ul li:nth-child(1) {
        float: center;   
        width: 100%; 
        margin-top: 30%;
    }
    .company .company ul li img {    
        margin-bottom: 4%;
    }
    .company .company_in ul li:nth-child(2) { 
        float: center;      
        width: 100%;  
        height: 600px;  
        background:url(/static/media/company_img01.bb0e9423.png) no-repeat center center / cover;  
    }
    .company .company_in ul li p {
        font-size: 2rem;
        color: #333;
        font-weight: 800;  
        line-height: 6rem;
    }    
    .company .company_in ul li h1 {
        font-size: 1.2rem;
        color: #333;
        font-weight: 900; 
        padding-left: 4px;
    }
}








.referenceSworp{
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
    align-items: center;
    overflow-x: hidden;
}
.reference_in {
    margin-top: 25%;
    width: 80%;
    max-width: 1224px;
    margin-bottom: 10%;
}
.topimg{
    position: absolute;
    width: 100%;
    top: -100px;
}
.topimg img{
    width: 100%;
}


.reference .reference_in table {
    width: 100%;
    margin-top: 40px;
    border-collapse: collapse;
    border-top: 2px solid rgb(0, 0, 0);
    border-bottom: 2px solid rgb(0, 0, 0);
}
.reference .reference_in table tr {
    border-bottom: .5px solid rgb(0, 0, 0);
}
.reference .reference_in table td {
    padding: 20px 10px;
    vertical-align:middle;
  }
.reference .reference_in table td:nth-child(1) {
    max-width: 100px;
    width:10%;
    text-align: center;
}
.reference .reference_in table td:nth-child(2) {
    max-width: 650px;
    width:60%;
    text-overflow: ellipsis;
}
.reference .reference_in table td:nth-child(3) {
    max-width: 200px;
    width:20%;
    text-align: center;
}
.reference .reference_in p {
    font-size: 3rem;
    color: #333;
    font-weight: 800;
    line-height: 2rem;
}
.reference .reference_in h1 {
    font-size: 1.4rem;
    color: #333;
    font-weight: 700;
}
.Hgreenfont{
    color: #47BD9C;
    font-weight: 600;
}
.big_button {
    border: 2px solid rgb(0, 0, 0);
    background-color: #fff;
    margin: 60px 0px;
    padding: 10px 25%;
    width: 100%;
    cursor: pointer;
}
.td_button {
    border: 1.5px solid rgb(0, 0, 0);
    background-color: #fff;
    padding: 5px 20px 5px 20px;
    width: 100%;
    font-size: .8rem;
    color: rgb(0, 0, 0) !important;
    /* background:url("../../resources/icon_down.svg") no-repeat left center / cover;
    background-size: 20px;
    background-position: 15px 5px; */
}
.button_black:active,s
.button_black:hover,
.button_black:focus {
  background: #eee;
  outline: 0;
  transition: 0.8s;
}
.button_black :disabled {
  opacity: 0.5;
}

@media all and (max-width:480px){

    .reference .reference_in p {
        font-size: 2rem;
        color: #333;
        font-weight: 800;
        line-height: 6rem;
    }
    .reference .reference_in h1 {
        font-size: 1.2rem;
        color: #333;
        font-weight: 900;
        padding-left: 4px;
    }


    .reference .reference_in table td {
        display: inline-block;
        padding: 20px 10px;
        vertical-align:middle;        }
    .reference .reference_in table td:nth-child(1) {
        max-width: 15%;
        width:100%;
        text-align: center;
        vertical-align: top;
    }
    .reference .reference_in table td:nth-child(2) {
        max-width: 80%;
        width:100%;
        text-overflow: ellipsis;
    }
    .reference .reference_in table td:nth-child(3) {
        max-width: 100%;
        width:100%;
        text-align: right;       }

}








.vidoSworp{
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
    align-items: center;
    overflow-x: hidden;
}
.vido_in {
    margin-top: 25%;
    width: 90%;
    max-width: 1224px;
    margin-bottom: 10%;
}

.topimg{
    position: absolute;
    width: 100%;
    top: -100px;
}
.topimg img{
    width: 100%;
}

.vido .vido_in .Vidolist {
    display: inline-block;
    width: 100%;
    margin: 30px 0px;
}
.vido .vido_in .Vidolist ul {
    float: left;
    width: calc(90%/3);  
    margin: 20px 1.3%;
    padding: 12px;
    border:1.5px solid #999;
    border-radius: 2%;
    box-shadow: 4px 4px 4px 3px rgba(46, 46, 46, 0.2);
    height: 340px;
}
.vido .vido_in ul li img{
    width: 100%;
    min-height: 190px;    
}


.vido .vido_in ul li:nth-child(1) {
    text-align: center;
}
.vido .vido_in ul li:nth-child(2) {
    font-size: .9rem;
    color: #333;
    font-weight: 500;
    padding: 10px 10px 0px 10px;

}
.vido .vido_in ul li:nth-child(3) {
    font-size: 1.2rem;
    color: #333;
    font-weight: 700;
    padding: 0px 10px 0px 10px;
}
.vido .vido_in p {
    font-size: 3rem;
    color: #333;
    font-weight: 800;
    line-height: 2rem;
}
.vido .vido_in h1 {
    font-size: 1.4rem;
    color: #333;
    font-weight: 700;
}
.Hgreenfont{
    color: #47BD9C;
    font-weight: 600;
}
.vido .vido_in button {
    border: 2px solid rgb(0, 0, 0);
    background-color: #fff;
    margin: 60px 0px;
    padding: 10px 25%;
    width: 100%;
}

@media all and (max-width:480px){

    .vido .vido_in ul li p {
        font-size: 2rem;
        color: #333;
        font-weight: 800;
        line-height: 6rem;
    }
    .vido .vido_in ul li h1 {
        font-size: 1.2rem;
        color: #333;
        font-weight: 900;
        padding-left: 4px;
    }
    .vido .vido_in .Vidolist {
        display: inline-block;
        width: 100%;
        margin: 60px 0px;
    }
    .vido .vido_in .Vidolist ul {
        float: center;
        width: calc(100%/1);
        margin: 20px 0%;
    }

}








