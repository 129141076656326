.typewriter {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0rem solid rgb(0, 0, 0); /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0rem; /* Adjust as needed */
    animation: 
      typing 4.5s steps(40, end),
      blink-caret .75s step-end infinite;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    70% { border-color: rgb(0, 0, 0); }
  }


 /* CSS Animated Background Gradient */

  .hbgdo {
    position:absolute;
    z-index: -1;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    background-color:black;
    -webkit-filter: grayscale(20%) brightness(70%); /* Safari 6.0 - 9.0 */
    filter: grayscale(20%) brightness(70%);
    
}

.type-a {  
    width: 100vw;
    height: 100vh; 
    -webkit-animation: zoom-a 10s linear infinite alternate; /* Safari 4+ */
    -moz-animation:    zoom-a 10s linear infinite alternate; /* Fx 5+ */
    -o-animation:      zoom-a 10s linear infinite alternate; /* Opera 12+ */
    animation:         zoom-a 10s linear infinite alternate; /* IE 10+, Fx 29+ */
  }
@-webkit-keyframes zoom-a {
	0% { background-size: 120% auto; }
	100% { background-size: 140% auto; }
}
@-moz-keyframes zoom-a {
    0% { background-size: 120% auto; }
	100% { background-size: 140% auto; }
}
@-o-keyframes zoom-a {
    0% { background-size: 120% auto; }
	100% { background-size: 140% auto; }
}
@keyframes zoom-a {
	0% { background-size: 120% auto; }
	100% { background-size: 140% auto; }
}

.type-b {  
    width: 110%;
    height: 110%;
    -webkit-animation: zoom-a 6s infinite alternate; /* Safari 6+ */
    -moz-animation:    zoom-a 6s infinite alternate; /* Fx 5+ */
    -o-animation:      zoom-a 6s infinite alternate; /* Opera 12+ */
    animation:         zoom-a 6s infinite alternate; /* IE 10+, Fx 29+ */
  }
  @-webkit-keyframes zoom-a {
	0% { background-size: 140% auto; }
    50% { background-size: 120% auto; }
	100% { background-size: 105% auto; }
}
@-moz-keyframes zoom-a {
    0% { background-size: 140% auto; }
    50% { background-size: 120% auto; }
	100% { background-size: 105% auto; }
}
@-o-keyframes zoom-a {
    0% { background-size: 140% auto; }
    50% { background-size: 120% auto; }
	100% { background-size: 105% auto; }
}
@keyframes zoom-a {
	0% { background-size: 140% auto; }
    50% { background-size: 120% auto; }
	100% { background-size: 105% auto; }
}

.chatbox {animation: motion 0.3s linear 0s infinite alternate; margin-top: 0;
	-webkit-animation: motion 0.3s linear 0s infinite alternate; margin-top: 0;
}
        
@keyframes motion {
	0% {margin-top: 0px;}
	100% {margin-top: 10px;}
}

-webkit-@keyframes motion {
	0% {margin-top: 0px;}
	100% {margin-top: 10px;}
}

.z-scroll {
    opacity: 0;
    transform: translateX(-80px);
    transition: transform 1.0s, opacity 1.0s;
  }
  .z-scroll.right-on {
    opacity: 1;
    transform: translateX(0px);
    transition: transform 1.0s, opacity 1.0s;
  }

#talk{
    position: relative;
    
}
.talk-txt {top:-2vh;}
.talk-txt .title {
  display:block;
  width: 66vw;
  margin:10vh auto 0;
}

.talk-txt span {
    width:50vw;
    display:block;
    margin:auto;
    position:relative;
}

.talk-txt div.tlk_01 {
    margin-top:15vh;
    margin-left:10vh;
}
.talk-txt div.tlk_02 {
    margin-top:5vh;
    margin-left:30vh;
}
.talk-txt div.tlk_03 {
    margin-top:5vh;

}
.talk-txt div.tlk_01 img {
    width:19vw;
}
.talk-txt div.tlk_02 img {
    width:16vw;
}
.talk-txt div.tlk_03 img {
    width:25vw;
}

/* animation */
.talk-txt div.tlk_01.z-scroll2.up-on {
    transition-delay: 0.4s;
}
.talk-txt div.tlk_02.z-scroll2.up-on {
    transition-delay: 1.6s;
}
.talk-txt div.tlk_03.z-scroll2.up-on {
    transition-delay: 2.8s;
}
#talk .title {
  transition-delay: 3.6s;
}



/* CSS Animated Background Gradient */



.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 9;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}


  /* CSS Animated Background Gradient */
  .HTbg {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 300% 300%;
    background-image: linear-gradient(
          -45deg, 
            rgba(59,173,227,1) 0%, 
          rgba(87,111,230,1) 25%, 
          rgba(71,189,126,1) 51%, 
          rgba(255,245,0,1) 100%
          /* rgba(59,173,227,1) 0%, 
          rgba(87,111,230,1) 25%, 
          rgba(152,68,183,1) 51%, 
          rgba(255,53,127,1) 100% */
    );  
    animation: AnimateBG 20s ease infinite;
  }
  
  @keyframes AnimateBG { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }


  /* Seeding */
  





  