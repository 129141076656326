
#footer {
    display: flex;
    justify-Content: space-around;
    background:rgb(63, 63, 63);
 
}
.footer-in{
    display: block;
    width: 90vw;
    height: auto;
    max-width: 1224px;
    margin-top: 5vh;
    margin-bottom: 5vh;
    color: #fff;
    font-size: .8rem;
}
