.vidoSworp{
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
    align-items: center;
    overflow-x: hidden;
}
.vido_in {
    margin-top: 25%;
    width: 90%;
    max-width: 1224px;
    margin-bottom: 10%;
}

.topimg{
    position: absolute;
    width: 100%;
    top: -100px;
}
.topimg img{
    width: 100%;
}

.vido .vido_in .Vidolist {
    display: inline-block;
    width: 100%;
    margin: 30px 0px;
}
.vido .vido_in .Vidolist ul {
    float: left;
    width: calc(90%/3);  
    margin: 20px 1.3%;
    padding: 12px;
    border:1.5px solid #999;
    border-radius: 2%;
    box-shadow: 4px 4px 4px 3px rgba(46, 46, 46, 0.2);
    height: 340px;
}
.vido .vido_in ul li img{
    width: 100%;
    min-height: 190px;    
}


.vido .vido_in ul li:nth-child(1) {
    text-align: center;
}
.vido .vido_in ul li:nth-child(2) {
    font-size: .9rem;
    color: #333;
    font-weight: 500;
    padding: 10px 10px 0px 10px;

}
.vido .vido_in ul li:nth-child(3) {
    font-size: 1.2rem;
    color: #333;
    font-weight: 700;
    padding: 0px 10px 0px 10px;
}
.vido .vido_in p {
    font-size: 3rem;
    color: #333;
    font-weight: 800;
    line-height: 2rem;
}
.vido .vido_in h1 {
    font-size: 1.4rem;
    color: #333;
    font-weight: 700;
}
.Hgreenfont{
    color: #47BD9C;
    font-weight: 600;
}
.vido .vido_in button {
    border: 2px solid rgb(0, 0, 0);
    background-color: #fff;
    margin: 60px 0px;
    padding: 10px 25%;
    width: 100%;
}

@media all and (max-width:480px){

    .vido .vido_in ul li p {
        font-size: 2rem;
        color: #333;
        font-weight: 800;
        line-height: 6rem;
    }
    .vido .vido_in ul li h1 {
        font-size: 1.2rem;
        color: #333;
        font-weight: 900;
        padding-left: 4px;
    }
    .vido .vido_in .Vidolist {
        display: inline-block;
        width: 100%;
        margin: 60px 0px;
    }
    .vido .vido_in .Vidolist ul {
        float: center;
        width: calc(100%/1);
        margin: 20px 0%;
    }

}







