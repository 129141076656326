.companySworp{
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
 
    overflow-x: hidden;     
    /* background:url("../../resources/vidotop_img01.png") no-repeat top center / 100%;  
    background-position: -70px 0px 0px 0px; */
}
.topimg{
    position: absolute;
    width: 100%;
    top: -100px;  
}
.topimg img{
    width: 100%;    
}
.company_in {
    margin-top: 25%;
    width: 80%;
    max-width: 1224px;    
    margin-bottom: 10%;    
}
.company .company_in ul li:nth-child(1) {  
    float: left;
    width: 50%; 
    margin-right: 2%;
    margin-top: 2%;
}
.company .company_in ul li:nth-child(2) {     
    float: right;
    width: 40%;  
    height: 560px;  
    background:url("../../resources/company_img01.png") no-repeat center center / cover;  
    font-size: 1rem;
    color: #333;
    font-weight: 400; 
    padding-left: 4px;
    margin-top: 50px;
}
.company .company_in ul li p {
    font-size: 3rem;
    color: #333;
    font-weight: 800;  
    line-height: 6rem;
}    
.company .company_in ul li h1 {
    font-size: 1.2rem;
    color: #333;
    font-weight: 900; 
    padding-left: 4px;
}

.Hgreenfont{
    color: #47BD9C;
    font-weight: 600;  
}










@media all and (max-width:480px){   
    .company .company_in ul li:nth-child(1) {
        float: center;   
        width: 100%; 
        margin-top: 30%;
    }
    .company .company ul li img {    
        margin-bottom: 4%;
    }
    .company .company_in ul li:nth-child(2) { 
        float: center;      
        width: 100%;  
        height: 600px;  
        background:url("../../resources/company_img01.png") no-repeat center center / cover;  
    }
    .company .company_in ul li p {
        font-size: 2rem;
        color: #333;
        font-weight: 800;  
        line-height: 6rem;
    }    
    .company .company_in ul li h1 {
        font-size: 1.2rem;
        color: #333;
        font-weight: 900; 
        padding-left: 4px;
    }
}







