.referenceSworp{
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
    align-items: center;
    overflow-x: hidden;
}
.reference_in {
    margin-top: 25%;
    width: 80%;
    max-width: 1224px;
    margin-bottom: 10%;
}
.topimg{
    position: absolute;
    width: 100%;
    top: -100px;
}
.topimg img{
    width: 100%;
}


.reference .reference_in table {
    width: 100%;
    margin-top: 40px;
    border-collapse: collapse;
    border-top: 2px solid rgb(0, 0, 0);
    border-bottom: 2px solid rgb(0, 0, 0);
}
.reference .reference_in table tr {
    border-bottom: .5px solid rgb(0, 0, 0);
}
.reference .reference_in table td {
    padding: 20px 10px;
    vertical-align:middle;
  }
.reference .reference_in table td:nth-child(1) {
    max-width: 100px;
    width:10%;
    text-align: center;
}
.reference .reference_in table td:nth-child(2) {
    max-width: 650px;
    width:60%;
    text-overflow: ellipsis;
}
.reference .reference_in table td:nth-child(3) {
    max-width: 200px;
    width:20%;
    text-align: center;
}
.reference .reference_in p {
    font-size: 3rem;
    color: #333;
    font-weight: 800;
    line-height: 2rem;
}
.reference .reference_in h1 {
    font-size: 1.4rem;
    color: #333;
    font-weight: 700;
}
.Hgreenfont{
    color: #47BD9C;
    font-weight: 600;
}
.big_button {
    border: 2px solid rgb(0, 0, 0);
    background-color: #fff;
    margin: 60px 0px;
    padding: 10px 25%;
    width: 100%;
    cursor: pointer;
}
.td_button {
    border: 1.5px solid rgb(0, 0, 0);
    background-color: #fff;
    padding: 5px 20px 5px 20px;
    width: 100%;
    font-size: .8rem;
    color: rgb(0, 0, 0) !important;
    /* background:url("../../resources/icon_down.svg") no-repeat left center / cover;
    background-size: 20px;
    background-position: 15px 5px; */
}
.button_black:active,s
.button_black:hover,
.button_black:focus {
  background: #eee;
  outline: 0;
  transition: 0.8s;
}
.button_black :disabled {
  opacity: 0.5;
}

@media all and (max-width:480px){

    .reference .reference_in p {
        font-size: 2rem;
        color: #333;
        font-weight: 800;
        line-height: 6rem;
    }
    .reference .reference_in h1 {
        font-size: 1.2rem;
        color: #333;
        font-weight: 900;
        padding-left: 4px;
    }


    .reference .reference_in table td {
        display: inline-block;
        padding: 20px 10px;
        vertical-align:middle;        }
    .reference .reference_in table td:nth-child(1) {
        max-width: 15%;
        width:100%;
        text-align: center;
        vertical-align: top;
    }
    .reference .reference_in table td:nth-child(2) {
        max-width: 80%;
        width:100%;
        text-overflow: ellipsis;
    }
    .reference .reference_in table td:nth-child(3) {
        max-width: 100%;
        width:100%;
        text-align: right;       }

}







